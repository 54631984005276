var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "center" },
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.headBtnOptions,
          "head-title": _vm.$t(`cip.plat.wfOps.done.title.indexHeadTitle`),
        },
      }),
      _c("grid-head-layout", {
        ref: "gridHeadLayout",
        attrs: { "search-columns": _vm.searchColumns },
        on: {
          "grid-head-search": _vm.gridHeadSearch,
          "grid-head-empty": _vm.gridHeadEmpty,
        },
      }),
      _c("grid-layout", {
        ref: "gridLayOut",
        attrs: {
          "table-options": _vm.option,
          "table-data": _vm.data,
          "table-loading": _vm.loading,
          "data-total": _vm.page.total,
          "grid-row-btn": _vm.gridRowBtn,
          page: _vm.page,
        },
        on: {
          "page-current-change": _vm.onLoad,
          "page-size-change": _vm.onLoad,
          "page-refresh-change": _vm.onLoad,
          "row-flow": _vm.handleFlow,
          "row-detail": _vm.handleDetail,
        },
      }),
      _vm.bpmnVisible
        ? _c(
            "CommonDialog",
            {
              attrs: {
                customClass: "wf-dialog",
                width: "50%",
                showBtn: false,
                dialogTitle: _vm.$t(
                  `cip.plat.wfOps.done.title.flowChartHeadTitle`
                ),
              },
              on: {
                cancel: function ($event) {
                  _vm.bpmnVisible = false
                },
              },
            },
            [
              _c("wf-design", {
                ref: "bpmn",
                staticStyle: { height: "500px" },
                attrs: { options: _vm.bpmnOption },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-drawer",
        {
          attrs: {
            visible: _vm.detailVisible,
            title: _vm.form.processDefinitionName,
            "custom-class": "wf-drawer",
            size: "60%",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.detailVisible = $event
            },
          },
        },
        [
          _vm.detailVisible
            ? _c("task-detail", {
                attrs: {
                  taskId: _vm.form.taskId,
                  processInstanceId: _vm.form.processInstanceId,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }