<template>
  <div class="center">
    <!--列表label-->
    <head-layout :head-btn-options="headBtnOptions"
                 :head-title="$t(`cip.plat.wfOps.done.title.indexHeadTitle`)"></head-layout>
    <!--列表查询条件和头部按钮-->
    <grid-head-layout ref="gridHeadLayout"
                      :search-columns="searchColumns"
                      @grid-head-search="gridHeadSearch"
                      @grid-head-empty="gridHeadEmpty"></grid-head-layout>
    <grid-layout ref="gridLayOut"
                 :table-options="option"
                 :table-data="data"
                 :table-loading="loading"
                 :data-total="page.total"
                 :grid-row-btn="gridRowBtn"
                 :page="page"
                 @page-current-change="onLoad"
                 @page-size-change="onLoad"
                 @page-refresh-change="onLoad"
                 @row-flow="handleFlow"
                 @row-detail="handleDetail"></grid-layout>

    <CommonDialog v-if="bpmnVisible" customClass="wf-dialog" width="50%" :showBtn="false" :dialogTitle="$t(`cip.plat.wfOps.done.title.flowChartHeadTitle`)" @cancel="bpmnVisible = false">
      <wf-design ref="bpmn"
                 style="height: 500px;"
                 :options="bpmnOption"></wf-design>
    </CommonDialog>
    <el-drawer :visible.sync="detailVisible"
               :title="form.processDefinitionName"
               custom-class="wf-drawer"
               size="60%"
               append-to-body>
      <task-detail v-if="detailVisible"
                   :taskId="form.taskId"
                   :processInstanceId="form.processInstanceId"></task-detail>
    </el-drawer>
  </div>

</template>

<script>
import { detail } from '@/api/plugin/workflow/process'
import { getDoneList as getList } from "@/api/plugin/workflow/ops";
import { mapGetters } from "vuex";

import TaskDetail from './detail.vue'
import exForm from '../mixins/ex-form'
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import CommonDialog from "@/components/CommonDialog"
export default {
  mixins: [exForm],
  components: {
    TaskDetail, GridLayout, HeadLayout,CommonDialog
  },
  data () {
    return {
      form: {},
      query: {},
      loading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        size: 'mini',
        height: 'auto',
        calcHeight: 30,
        tip: false,
        border: true,
        selection: true,
        dialogType: 'drawer',
        addBtn: false,
        editBtn: false,
        delBtn: false,
        align: 'center',
        searchSize: 'mini',
        searchIndex: 3,
        searchIcon: true,
        column: [
          {
            label: this.$t('cip.plat.wfOps.done.field.nameOfTheProcess'),
            prop: 'processDefinitionName',
            search: true,
            overHidden: true,
            width: 200,
            align:'left',
          },
          {
            label: this.$t('cip.plat.wfOps.done.field.ProcessIdentification'),
            prop: 'processDefinitionKey',
            search: true,
            overHidden: true,
            width: 200,
            align:'left',
          },
          {
            label: this.$t('cip.plat.wfOps.done.field.SerialNumber'),
            prop: 'serialNumber',
            bind: 'variables.serialNumber',
            search: true,
            overHidden: true,
            align:'left',
          },
          {
            label: this.$t('cip.plat.wfOps.done.field.category'),
            row: true,
            align:'center',
            type: 'tree',
            width: 120,
            overHidden: true,
            dicUrl: '/api/sinoma-workflow/design/category/tree',
            props: {
              label: 'name',
              value: 'id'
            },
            prop: "category",
            search: true,
          },
          {
            label:  this.$t('cip.plat.wfOps.done.field.applicant'),
            prop: 'startUsername',
            search: true,
            width: 200,
            overHidden: true,
          },
          {
            label:  this.$t('cip.plat.wfOps.done.field.StartTime'),
            prop: 'createTime',
            align:'center',
            type: 'datetime',
            overHidden: true,
            format: 'yyyy-MM-dd HH:mm',
            width: 165,
          },
          {
            label:  this.$t('cip.plat.wfOps.done.field.EndTime'),
            prop: 'endTime',
            align:'center',
            type: 'datetime',
            format: 'yyyy-MM-dd HH:mm',
            overHidden: true,
            width: 165,
          },
          {
            label:  this.$t('cip.plat.wfOps.done.field.StartTime'),
            prop: 'startTimeRange',
            align:'center',
            type: 'datetime',
            dataType: 'string',
            format: 'yyyy-MM-dd HH:mm:ss',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            hide: true,
            search: true,
            searchRange: true,
            width: 165,
            overHidden: true,
          },
          {
            label:  this.$t('cip.plat.wfOps.done.field.EndTime'),
            prop: 'endTimeRange',
            align:'center',
            type: 'datetime',
            dataType: 'string',
            format: 'yyyy-MM-dd HH:mm:ss',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            hide: true,
            search: true,
            searchRange: true,
            width: 165,
            overHidden: true,
          },
          {
            label:  this.$t('cip.plat.wfOps.done.field.Status'),
            align:'center',
            prop: 'processIsFinished',
            width: 165,
            overHidden: true,
            dicData: [{
              label: this.$t('cip.plat.wfOps.done.field.finished'),
              value: 'finished'
            }, {
              label: this.$t('cip.plat.wfOps.done.field.terminate'),
              value: 'terminate'
            }, {
              label: this.$t('cip.plat.wfOps.done.field.withdraw'),
              value: 'withdraw'
            }, {
              label: this.$t('cip.plat.wfOps.done.field.deleted'),
              value: 'deleted'
            }],
            type: 'select',
            search: true
          },
        ]
      },
      data: [],
      bpmnVisible: false,
      bpmnOption: {},
      detailVisible: false
    };
  },
  computed: {
    ...mapGetters(["permission","language"]),
    ids () {
      let ids = [];
      let selectionList = this.$refs.gridLayOut.selectionList
      selectionList.forEach(ele => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    searchColumns () {
      return [
        {
          label: this.$t('cip.plat.wfOps.done.field.nameOfTheProcess'),
          prop: "processDefinitionName",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t('cip.plat.wfOps.done.msg.allName'),
        },
      ]
    },
    headBtnOptions () {
      let result = [];
      return result;
    },
    // 行按钮添加 add by steve
    gridRowBtn () {
      //新增按钮
      return [
        {
          label: this.$t('cip.plat.wfOps.done.btn.detailBtn'),
          emit: "row-detail",
          type: "primary",
          icon: "el-icon-plus",
        }, {
          label: this.$t('cip.plat.wfOps.done.btn.flowBtn'),
          emit: "row-flow",
          type: "primary",
          icon: "el-icon-plus",
        }
      ]
    },
  },
  mounted () {
    this.onLoad(this.page, {});
  },
  methods: {
    // 列表查询 add by steve
    gridHeadSearch (searchForm) {
      this.page = {
        pageSize: 20,
        currentPage: 1,
        total: 0
      }
      this.onLoad(this.page, searchForm);
    },
    // 查询重置 add by steve
    gridHeadEmpty (searchForm) {
      this.page = {
        pageSize: 20,
        currentPage: 1,
        total: 0
      }
      this.onLoad(this.page, searchForm);
    },
    handleDetail (row) {
      this.dynamicRoute(row, 'detail', true).then(() => {
        this.form = { ...row }
        this.detailVisible = true
      })
    },
    handleFlow (row) {
      const { taskId, processInstanceId } = row
      detail({ taskId, processInsId: processInstanceId }).then(res => {
        const { process, flow } = res.data.data

        this.bpmnOption = {
          mode: 'view',
          xml: process.xml,
          flows: this.handleResolveFlows(flow),
          lang: this.language
        }

        this.bpmnVisible = true
      })
    },

    async onLoad (page, params = {}) {
      this.page = page
      this.loading = true;
      getList(page.currentPage, page.pageSize, Object.assign(params, this.$refs.gridHeadLayout.searchForm)).then(res => {
        const data = res.data.data
        // this.page.total = data.total
        this.$refs.gridLayOut.page.total = data.total
        this.data = data.records
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    }
  }
};
</script>

<style lang="scss">
.wf-drawer {
  .el-drawer__header {
    padding: 10px !important;
    color: #333333;
    font-size: 25px;
    margin: 10px 0px 0px !important;
  }
  .el-drawer__body {
    padding: 10px !important;
    overflow: auto;
  }
}
</style>
<style scoped>
::v-deep .bjs-powered-by {
  display: none;
}
</style>
